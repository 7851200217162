import { Button } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export const ChangeLanguageButton: FC<{ absolute?: boolean; menu?: boolean }> = ({
  absolute,
  menu,
}) => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const to = location.pathname.startsWith('/ru')
    ? '/en' + location.pathname.substring(3)
    : location.pathname.startsWith('/en')
    ? '/ru' + location.pathname.substring(3)
    : '/ru' + location.pathname;

  return (
    <Link
      className={`flex self-center text-white
         ${absolute ? `absolute z-10 right-2 top-4 ${menu ? 'lg:hidden' : ''}` : ''}
      `}
      to={to}
    >
      <Button type="link">
        <img alt={i18n.language} className="max-h-full" src={`/images/lang/${i18n.language}.png`} />
      </Button>
    </Link>
  );
};
